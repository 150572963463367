import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { Component } from "react";
import { Provider } from "react-redux";
import PrivateRoute from "./components/private-route/PrivateRoute";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap";
import "../node_modules/font-awesome/css/font-awesome.css";
import "../node_modules/jquery/dist/jquery.min";
import "../node_modules/popper.js/dist/popper";

import PageSpinner from './PageSpinner';


if (localStorage.adminToken) {
  const token = localStorage.adminToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "./login";
  }
}

const Login = React.lazy(() => import("./components/auth/Login"));
const Dashboard = React.lazy(() => import("./components/pages/Dashboard"));
const Forgot = React.lazy(() => import("./components/auth/Forgot"));
const NotFound = React.lazy(() => import("./components/layout/NotFound"));
const Register = React.lazy(() => import("./components/auth/Register"));
const Changepassword = React.lazy(() => import("./components/pages/Changepassword"));
const Faq = React.lazy(() => import("./components/pages/Faq"));
const Newsletter = React.lazy(() => import("./components/pages/Newsletter"));
const Support_reply = React.lazy(() => import("./components/partials/SupportReplyModal"));
const userdataview = React.lazy(() => import("./components/partials/userdataview"));
const airdropuserdataview = React.lazy(() => import("./components/partials/airdropuserviewmodel"));
const transactionview = React.lazy(() => import("./components/partials/transactionview"));
const tokentransfer = React.lazy(() => import("./components/pages/TokenTransfer"));
const tokensend = React.lazy(() => import("./components/pages/Tokensend"));
const Sendhistory = React.lazy(() => import("./components/pages/tokensendhistory"));
const Airdrop = React.lazy(() => import("./components/pages/Airdrop"));
const AirdropHistory = React.lazy(() => import("./components/pages/Airdrophistory"));
const SetToken = React.lazy(() => import("./components/pages/setToken"));
const SocialMedia = React.lazy(() => import("./components/pages/socialMedia"))



class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router basename={"/"}>
          <div className="App">
            <Switch>
              <React.Suspense fallback={<PageSpinner />}>
                <Route exact path="/" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/forgot" component={Forgot} />
                <Switch> 
                  <PrivateRoute exact path="/dashboard" component={Dashboard} />
                  <PrivateRoute
                    exact
                    path="/changepassword"
                    component={Changepassword}
                  />
                  <PrivateRoute
                  exact
                  path="/setToken"
                  component={SetToken}
                  />
                   <PrivateRoute
                  exact
                  path="/socialmedia"
                  component={SocialMedia}
                  />
                  <PrivateRoute exact path="/faq" component={Faq} />
                  <PrivateRoute
                    exact
                    path="/tokenhistory"
                    component={Sendhistory}
                  />
                  <PrivateRoute
                    exact
                    path="/airdrophistory"
                    component={AirdropHistory}
                  />
                  <PrivateRoute exact path="/newsletter" component={Newsletter} />
                  <PrivateRoute
                    exact
                    path="/support_reply/:id"
                    component={Support_reply}
                  />
                  <PrivateRoute
                    exact
                    path="/userdata/:id"
                    component={userdataview}
                  />
                  <PrivateRoute
                    exact
                    path="/airdropuserdata/:id"
                    component={airdropuserdataview}
                  />
                  <PrivateRoute
                    exact
                    path="/transactionview/:id"
                    component={transactionview}
                  />
                  <PrivateRoute
                    exact
                    path="/tokentransfer"
                    component={tokentransfer}
                  />
                  <PrivateRoute
                    exact
                    path="/airdrop"
                    component={Airdrop}
                  />
                  <PrivateRoute
                    exact
                    path="/tokensend"
                    component={tokensend}
                  />
                </Switch>
              </React.Suspense>
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
