// let Env = "local";
let Env = "production";
let baseUrl = "";
let frontUrl = "";
let contractCreator = "";
let secretOrKey = "";
let icoContract = ""
// let dburl = "";
// let PrivateKey = "";
// let TokenAddress ="";
// let fromAddress = "";

if (Env == "local") {
  frontUrl = "http://localhost:3000/";
  baseUrl = "http://localhost:2053/";
  
  // contractCreator = "0x36d97F08535120313EC8488E1d320C4143EF4Cbe"; // testnet

  contractCreator= "0x0472B50E2aC7A10145B54BDf0789e8F72d399997"  // live net
  secretOrKey = "3TAXElQb9UnwkBv1RFb47w==";
   icoContract = "0x85D8CBAB1DC439d1b35fb43486763206e4e3ad65"


  // dburl = "mongodb://localhost:27017/orbitx";
  // fromAddress = "0x36d97F08535120313EC8488E1d320C4143EF4Cbe";
}
if (Env == "production") {
  // frontUrl = "https://orbit.wealwin.com/";
  // baseUrl = "https://orbitapi.wealwin.com/";
  

  frontUrl= "https://orbitxtoken.com/";
  baseUrl= "https://api.orbitxtoken.com/";

  // contractCreator = "0x36d97F08535120313EC8488E1d320C4143EF4Cbe"; //testnet
  contractCreator= "0x0472B50E2aC7A10145B54BDf0789e8F72d399997" //live net
   icoContract = "0x85D8CBAB1DC439d1b35fb43486763206e4e3ad65"

  secretOrKey = "3TAXElQb9UnwkBv1RFb47w==";

  //   dburl="mongodb//orbitdb:Uvsddserfdejfhew2fsdc@172.105.40.100:10730/orbitdb"
  //  PrivateKey = "e315aea309dc3b58130e491e6a96d289fbcfc746628c5292425cfd25b31d31e5";

  // TokenAddress = "0x68fC0b4d7838Fa1ea42158C5f63445b2AED6E785";
  // fromAddress = "0x36d97F08535120313EC8488E1d320C4143EF4Cbe";
}

module.exports = {
  Front_URL: frontUrl,
  baseUrl: baseUrl,
  contractCreator: contractCreator,
  secretOrKey:secretOrKey,
  icoContract :icoContract
  //   baseUrl : "http://localhost:2053/",

  // Backend_url :'http://localhost:2053',  //======live

  // secretOrKey : "3TAXElQb9UnwkBv1RFb47w==",

  // contractCreator:"0x36d97F08535120313EC8488E1d320C4143EF4Cbe",
};
